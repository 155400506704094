import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';
import { AssessmentQuestionnaireResponse, LoadingState } from 'types';
import * as api from 'api';
import { RootState } from 'store';
import _ from 'lodash';

export interface QuestionnaireResponsesState {
  [assetId: string]: {
    [questionnaireTableId: string]: {
      responses: AssessmentQuestionnaireResponse[];
      error: any;
      loading: LoadingState;
    };
  };
}

const name = 'questionnaireResponses';

export const initialState: QuestionnaireResponsesState = {};

/**
 * Keeps track of all questionnaire response requests made so they can be refetched in the future.
 */
const requestHistory: Record<string, () => Promise<AssessmentQuestionnaireResponse[]>> = {};

function getRequestHistoryId(assetTypeId: string, assetId: string, questionnaireTableId: string) {
  return `${assetTypeId}_${assetId}_${questionnaireTableId}`;
}

export const fetchQuestionnaireResponses = createAsyncThunk(
  `${name}/fetchQuestionnaireResponses`,
  async (
    {
      assetTypeId,
      assetId,
      questionnaireTableId,
      // fields and forceReloadResponses are mutually exclusive - if you reload response DO NOT pass in fields.
      fields = [],
      // If refreshResponses is true, the responses will be reloaded using the same field parameters as before.
      forceReloadResponses = false,
    }: {
      assetTypeId: string;
      assetId: string;
      questionnaireTableId: string;
      fields?: string[];
      forceReloadResponses?: boolean;
    },
    { rejectWithValue },
  ) => {
    const requestHistoryId = getRequestHistoryId(assetTypeId, assetId, questionnaireTableId);
    const previousRequest = requestHistory[requestHistoryId];

    const request =
      forceReloadResponses && !!previousRequest
        ? previousRequest
        : () => api.getAssessmentQuestionnaireResponses(assetTypeId, assetId, questionnaireTableId, { fields });

    requestHistory[requestHistoryId] = request;

    try {
      return await request();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ assetId, questionnaireTableId, fields, forceReloadResponses }, { getState }) => {
      const { questionnaireResponses } = getState() as RootState;
      const state = questionnaireResponses[assetId]?.[questionnaireTableId];
      const fetchStatus = state?.loading ?? LoadingState.idle;
      const responses = state?.responses ?? [];

      if (fetchStatus === LoadingState.loading) {
        return false;
      }

      if (forceReloadResponses) {
        return true;
      }

      // do not fetch if the requested fields all exist in the store already
      if (
        fetchStatus === LoadingState.succeed &&
        fields?.length &&
        responses.length &&
        _.difference(fields, Object.keys(responses[0])).length === 0
      ) {
        return false;
      }

      return true;
    },
  },
);

const questionnaireResponsesSlice: Slice<QuestionnaireResponsesState> = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionnaireResponses.pending, (stateDraft, { meta }) => {
      const { assetId, questionnaireTableId } = meta.arg;

      if (!stateDraft[assetId]) {
        stateDraft[assetId] = {};
      }

      stateDraft[assetId][questionnaireTableId] = {
        responses: [],
        error: null,
        loading: LoadingState.loading,
      };
    });

    builder.addCase(fetchQuestionnaireResponses.fulfilled, (stateDraft, { payload: responses, meta }) => {
      const { assetId, questionnaireTableId } = meta.arg;
      const stateSlice = stateDraft[assetId]?.[questionnaireTableId];

      stateSlice.responses = responses;
      stateSlice.loading = LoadingState.succeed;
    });

    builder.addCase(fetchQuestionnaireResponses.rejected, (stateDraft, { payload: error, meta }) => {
      const { assetId, questionnaireTableId } = meta.arg;
      const stateSlice = stateDraft[assetId]?.[questionnaireTableId];

      stateSlice.error = error;
      stateSlice.loading = LoadingState.failed;
    });
  },
});

export const selectQuestionnaireResponses = createSelector(
  (state: RootState, assetId: string, questionnaireTableId: string) =>
    state.questionnaireResponses[assetId]?.[questionnaireTableId],
  (stateSlice) => {
    return (
      stateSlice ?? {
        responses: [],
        error: null,
        loading: LoadingState.loading,
      }
    );
  },
);

export default questionnaireResponsesSlice.reducer;
