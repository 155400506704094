import { addFieldNameToSectionAttributeTypes } from 'api/helpers';
import JSONAPISerializer from 'json-api-serializer';
import { AssetRecordType, CustomerObjectContainerType, Section } from 'types';

export const TEMPORARY_METADATA_SECTION_ID = 999;

export default function register(serializer: JSONAPISerializer): void {
  serializer.register('record_types', {
    relationships: {
      attributeTypes: {
        type: 'attribute_types',
      },
    },
    afterDeserialize: (data: AssetRecordType) => {
      data.typeName = CustomerObjectContainerType.RECORD_TYPES;

      const metaDataItems = data.attributeTypes
        .filter((type) => type.isMetadata && type.fieldName !== 'metadata.workflow_status')
        .map((type) => ({ id: type.id, type: 'attribute_types' }));

      if (data.sections) {
        // SNP-1535: Temporary section until activity logging service is created.
        data.sections = [
          ...data.sections.filter((section: any) => section.items.length > 0),
          {
            id: TEMPORARY_METADATA_SECTION_ID,
            name: 'Additional information',
            items: metaDataItems,
          },
        ] as Section[];

        addFieldNameToSectionAttributeTypes(data);
      }

      return data;
    },
  });
}
