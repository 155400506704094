import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import { AttributeType } from 'types';
import { Relationship } from 'types/Relationships';
import { deserializeRecordRelationships } from '../deserializers';

export default async function getRecordRelationships(relationship: {
  objectTypeId: string;
  objectId: string;
  adHocAttributeType: AttributeType;
}): Promise<Relationship[]> {
  const { objectTypeId: recordTypeId, objectId: recordId, adHocAttributeType } = relationship;

  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordTypeId}/records/${recordId}/relationships?field_name=${adHocAttributeType.fieldName}`,
  );

  return await handleResponse(response, (json: any) => deserializeRecordRelationships(json, { adHocAttributeType }));
}
