import AppConfig from 'AppConfig';
import { Asset, AssetAttributes, AssetType } from 'types';
import http from 'api/http';
import { convertAttributesToServerFormat, handleResponse } from 'api/helpers';
import { deserializeAsset } from '../deserializers';

export default async function updateAsset(
  assetType: AssetType,
  assetId: string,
  updatedAttributes: AssetAttributes,
): Promise<Asset> {
  const body: any = {
    data: {
      type: 'assets',
      attributes: {
        asset_attributes: convertAttributesToServerFormat(updatedAttributes, assetType.attributeTypes),
      },
    },
  };

  const response = await http.patch(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetType.id}/assets/${assetId}`,
    body,
  );

  return await handleResponse(response, (assetsJson) => {
    return deserializeAsset(assetsJson, assetType.attributeTypes);
  });
}
