import _ from 'lodash';
import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';
import { AssetRecordType, LoadingState } from 'types';
import * as api from 'api';
import { RootState } from 'store';

type RecordTypesById = { [recordTypeId: string]: AssetRecordType };
type LoadingStateById = { [recordTypeId: string]: LoadingState };
type ErrorById = { [recordTypeId: string]: any };

export interface RecordTypesState {
  allLoadingError: any;
  allLoadingState: LoadingState;
  ids: string[];

  byId: RecordTypesById;
  errorById: ErrorById;
  loadingStateById: LoadingStateById;
}

const initialState: RecordTypesState = {
  allLoadingError: null,
  allLoadingState: LoadingState.idle,
  ids: [],

  byId: {},
  errorById: {},
  loadingStateById: {},
};

export const fetchRecordTypes = createAsyncThunk(
  'recordTypes/fetchRecordTypes',
  async (_, { rejectWithValue }) => {
    try {
      return await api.getRecordTypes();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const loadingState = (getState() as RootState).recordTypes.allLoadingState;

      // Already fetched or in progress, don't need to re-fetch
      if (loadingState === LoadingState.loading || loadingState === LoadingState.succeed) {
        return false;
      }
    },
  },
);

export const fetchSingleRecordType = createAsyncThunk(
  'recordTypes/fetchSingleRecordType',
  async ({ recordTypeId }: { recordTypeId: string }, { rejectWithValue }) => {
    try {
      const recordType = await api.getRecordType(recordTypeId);
      return recordType;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ recordTypeId }, { getState }) => {
      const { recordTypes } = getState() as RootState;
      const fetchStatus = recordTypes.loadingStateById[recordTypeId];
      if (fetchStatus === LoadingState.loading || fetchStatus === LoadingState.succeed) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

const recordTypesSlice: Slice<RecordTypesState> = createSlice({
  name: 'recordTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * fetchRecordTypes
     */
    builder.addCase(fetchRecordTypes.pending, (state) => {
      state.allLoadingState = LoadingState.loading;
      state.byId = {};
      state.ids = [];
      state.allLoadingError = null;
    });

    builder.addCase(fetchRecordTypes.fulfilled, (state, { payload }) => {
      state.allLoadingState = LoadingState.succeed;
      state.byId = _.keyBy(payload, 'id');
      state.ids = payload.map((item) => item.id);
    });

    builder.addCase(fetchRecordTypes.rejected, (state, { payload: error }) => {
      state.allLoadingState = LoadingState.failed;
      state.byId = {};
      state.ids = [];
      state.allLoadingError = error;
    });

    /**
     * fetchSingleRecordType
     */
    builder.addCase(fetchSingleRecordType.pending, (state, { meta }) => {
      const { recordTypeId } = meta.arg;
      state.loadingStateById[recordTypeId] = LoadingState.loading;
      state.errorById[recordTypeId] = null;
    });

    builder.addCase(fetchSingleRecordType.fulfilled, (state, { payload }) => {
      const { id } = payload;
      state.loadingStateById[id] = LoadingState.succeed;
      state.byId[id] = payload;
    });

    builder.addCase(fetchSingleRecordType.rejected, (state, { payload: error, meta }) => {
      const { recordTypeId } = meta.arg;
      state.loadingStateById[recordTypeId] = LoadingState.failed;
      state.errorById[recordTypeId] = error;
    });
  },
});

export const selectSortedRecordTypes = createSelector(
  (state: RootState) => state.recordTypes,
  ({ ids, byId }) => {
    return ids.map((id) => byId[id]);
  },
);

export const selectRecordTypeById = createSelector(
  [(state: RootState) => state.recordTypes, (_: any, recordTypeId: string) => recordTypeId],
  ({ byId, loadingStateById, errorById }, recordTypeId) => {
    return {
      recordType: byId[recordTypeId] ?? null,
      loading: loadingStateById[recordTypeId] ?? LoadingState.loading,
      error: errorById[recordTypeId],
    };
  },
);

export default recordTypesSlice.reducer;
