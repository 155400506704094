import { AssessmentQuestionnaire, AssessmentQuestionnaireField, AssessmentQuestionnaireResponse } from 'types';
import { deserialize } from 'api/serializer';

interface DataTable {
  data: {
    table: {
      fieldDefs?: AssessmentQuestionnaireField[];
      rows?: {
        analytic: AssessmentQuestionnaireResponse[];
      };
    };
  };
}

export function deserializeAssessmentQuestionnaires(
  data: Record<string, any>,
  assetId: string,
): AssessmentQuestionnaire[] {
  return deserialize('assessment_questionnaires', data).map((item: any) => ({
    ...item,
    assetId,
  })) as AssessmentQuestionnaire[];
}

export function deserializeAssessmentQuestionnaireResponses(dataTable: DataTable): AssessmentQuestionnaireResponse[] {
  return dataTable.data.table.rows?.analytic ?? [];
}

export function deserializeAssessmentQuestionnaireFields(dataTable: DataTable): AssessmentQuestionnaireField[] {
  return dataTable.data.table.fieldDefs ?? [];
}
