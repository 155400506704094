import qs from 'qs';
import { DEFAULT_APP, KNOWN_APP_CONFIGURATIONS, UNKNOWN_APP } from './constants';

export function parseUrlParameters(locationSearch: string): qs.ParsedQs {
  return qs.parse(locationSearch.replace(/^\?/, ''));
}

export function getCategoriesFromUrl(searchParams: qs.ParsedQs): string[] {
  return searchParams?.category?.toString()?.split(',') ?? [];
}

export function convertCategoriesToUrl(urlParameters: qs.ParsedQs, categories: string[]): string {
  return qs.stringify({ ...urlParameters, category: categories.join(',') }, { encode: false });
}

export function getAppName(urlSearch: string): string {
  const categories = getCategoriesFromUrl(parseUrlParameters(urlSearch));

  if (categories.length === 0) {
    return DEFAULT_APP.name;
  }

  for (const app of Object.values(KNOWN_APP_CONFIGURATIONS)) {
    if (categories.some((category) => app.categories.includes(category))) {
      return app.name;
    }
  }

  return UNKNOWN_APP;
}

/**
 * Gets the categories from apps closest to the given category, preferring:
 * 1. To stay within the currently configured app, otherwise
 * 2. Return the closest of one of the known app categories configurations (e.g. Asset Inventory, Risk Register), otherwise
 * 3. Append the given category to the current app.
 */
export function getRelatedCategories(categoryToCompare: string, currentAppCategories: string[]): string[] {
  if (currentAppCategories.includes(categoryToCompare)) {
    return currentAppCategories;
  }

  for (const app of Object.values(KNOWN_APP_CONFIGURATIONS)) {
    if (app.categories.includes(categoryToCompare)) {
      return app.categories;
    }
  }

  return [...currentAppCategories, categoryToCompare];
}
