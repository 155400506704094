import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { AvailableQuestionnaire } from 'types';

export default async function getAvailableAssessmentQuestionnaires(
  assetId: string,
  assetTypeId: string,
): Promise<AvailableQuestionnaire[]> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}/available_assessment_questionnaires`,
  );

  return await handleResponse(response, (json) => {
    return json.data.map((item: any) => ({
      id: item.id,
      name: item.attributes.name,
    }));
  });
}
