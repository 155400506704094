import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserialize } from 'api/serializer';

type AssessmentAttachment = {
  id: string;
  fileUrl: string;
};

export default async function getAssessmentAttachmentDownloadUrl(
  questionnaireTableId: string,
  responseId: string,
  fieldName: string,
  fileId: string,
): Promise<string> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/assessment_attachments/tables/${questionnaireTableId}/rows/${responseId}/fields/${fieldName}/files/${fileId}`,
  );

  return await handleResponse(response, (json) => {
    const downloadInfo = deserialize('assessment_attachments', json) as AssessmentAttachment;
    return downloadInfo.fileUrl;
  });
}
