export enum DataTableFieldType {
  rowId = 'row_id',
  rowNumber = 'row_number',
  character = 'character',
  numeric = 'numeric',
  integer = 'integer',
  logical = 'logical',
  date = 'date',
  time = 'time',
  datetime = 'datetime',
  file = 'file',
  digitalSignature = 'digital_signature',
  select = 'select',
  json = 'json',
  userLookup = 'user_lookup',
  reference = 'reference',
}

export interface AssessmentQuestionnaireField {
  id: number;
  fieldName: string;
  fieldType: DataTableFieldType;
  displayName: string;
  tags: Record<string, any>;
}

export interface AssessmentQuestionnaireResponse {
  [key: string]: any;
}

export interface AssessmentQuestionnaire {
  id: string;
  name: string;
  numberOfAssessments: number;
  dataTableId: string;
  assetId: string;
}

export interface AvailableQuestionnaire {
  id: string;
  name: string;
}

export interface DistributedQuestionnaire {
  id: string;
  recipients: string[];
  recordIds: string[];
  tableId: string;
  dataTableId: number;
}
