import React from 'react';
import { Helmet } from 'react-helmet';
import Card from '@paprika/card';
import Heading from '@paprika/heading';
import useI18n from '@paprika/l10n/lib/useI18n';
import SanitizedText from 'components/common/SanitizedText';
import { ReactComponent as DilligentHBLogo } from './diligent-hb-logo.svg';
import './ErrorPage.scss';
import { getAppName } from 'hooks/useAssetTypeCategories';

interface ErrorProps {
  heading: React.ReactNode;
  body: React.ReactNode;
}

export default function ErrorPage({ heading, body }: ErrorProps): JSX.Element {
  const I18n = useI18n();
  const appName = getAppName(window.location.search);

  return (
    <>
      <Helmet>
        <title>{I18n.t([`error_page.page_title.${appName}`, `error_page.page_title.default`], { heading })}</title>
      </Helmet>
      <div className="error-page-wrapper">
        <Card className="error-page_card-wrapper">
          <DilligentHBLogo className="error-page-logo" />
          <hr className="error-page-hr" />
          <Heading level={1}>{heading}</Heading>
          <p>{body}</p>
        </Card>

        <SanitizedText
          className="error-page-footer"
          text={I18n.t('error_page.footer', {
            visitOurSupportPage: I18n.t('links.support_url'),
          })}
        />
      </div>
    </>
  );
}
