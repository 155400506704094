import React from 'react';
import * as api from 'api';
import { AssetInventorySession } from 'types/Session';

export const SessionContext = React.createContext({} as AssetInventorySession);

export function useSession(): AssetInventorySession {
  return React.useContext(SessionContext);
}

export default function SessionProvider({ children }: { children: React.ReactNode }): JSX.Element | null {
  const [session, setSession] = React.useState<AssetInventorySession>();

  React.useEffect(() => {
    (async function fetchSession(): Promise<any> {
      setSession(await api.getSession());
    })();
  }, []);

  if (session) {
    return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
  } else {
    return null;
  }
}
