import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import ErrorNotificationFallback from './ErrorNotificationFallback';
import { useLocation } from 'react-router-dom';

export default function ErrorBoundary({ children }: { children: React.ReactNode }): JSX.Element {
  const location = useLocation();

  return (
    <ReactErrorBoundary FallbackComponent={ErrorNotificationFallback} resetKeys={[location]}>
      {children}
    </ReactErrorBoundary>
  );
}
