import { CustomerObjectDescription, InvokeEventResponse } from 'types';
import { deserialize } from 'api/serializer';

export function deserializeInvokeEventResponse(
  data: Record<string, any>,
): InvokeEventResponse<CustomerObjectDescription> {
  return {
    errors: data?.meta?.errors ?? [],
    customerObject: deserialize('customer_object_description', data) as CustomerObjectDescription,
  };
}
