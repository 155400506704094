import _ from 'lodash';
import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';

import { AssetType, LoadingState } from 'types';
import * as api from 'api';
import { RootState } from 'store';

type AssetTypesById = { [assetTypeId: string]: AssetType };
type LoadingStateById = { [assetTypeId: string]: LoadingState };
type ErrorById = { [assetTypeId: string]: any };

export interface AssetTypesState {
  allLoadingError: any;
  allLoadingState: LoadingState;
  ids: string[];

  byId: AssetTypesById;
  errorById: ErrorById;
  loadingStateById: LoadingStateById;
}

const initialState: AssetTypesState = {
  allLoadingError: null,
  allLoadingState: LoadingState.idle,
  ids: [],

  byId: {},
  errorById: {},
  loadingStateById: {},
};

export const fetchAssetTypes = createAsyncThunk(
  'assetTypes/fetchAssetTypes',
  async (options: { assetTypeCategories: string[] }, { rejectWithValue }) => {
    try {
      return await api.getAssetTypes(options);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const loadingState = (getState() as RootState).assetTypes.allLoadingState;

      // Already fetched or in progress, don't need to re-fetch
      if (loadingState === LoadingState.loading || loadingState === LoadingState.succeed) {
        return false;
      }
    },
  },
);

export const fetchSingleAssetType = createAsyncThunk(
  'assetTypes/fetchSingleAssetType',
  async ({ assetTypeId }: { assetTypeId: string }, { rejectWithValue }) => {
    try {
      const assetType = await api.getAssetType(assetTypeId);
      return assetType;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ assetTypeId }, { getState }) => {
      const { assetTypes } = getState() as RootState;
      const fetchStatus = assetTypes.loadingStateById[assetTypeId];
      if (fetchStatus === LoadingState.loading || fetchStatus === LoadingState.succeed) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

const assetTypesSlice: Slice<AssetTypesState> = createSlice({
  name: 'assetTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * fetchAssetTypes
     */
    builder.addCase(fetchAssetTypes.pending, (state) => {
      state.allLoadingState = LoadingState.loading;
      state.byId = {};
      state.ids = [];
      state.allLoadingError = null;
    });

    builder.addCase(fetchAssetTypes.fulfilled, (state, { payload }) => {
      state.allLoadingState = LoadingState.succeed;
      state.byId = _.keyBy(payload, 'id');
      state.ids = payload.map((item) => item.id);
    });

    builder.addCase(fetchAssetTypes.rejected, (state, { payload: error }) => {
      state.allLoadingState = LoadingState.failed;
      state.byId = {};
      state.ids = [];
      state.allLoadingError = error;
    });

    /**
     * fetchSingleAssetType
     */
    builder.addCase(fetchSingleAssetType.pending, (state, { meta }) => {
      const { assetTypeId } = meta.arg;
      state.loadingStateById[assetTypeId] = LoadingState.loading;
      state.errorById[assetTypeId] = null;
    });

    builder.addCase(fetchSingleAssetType.fulfilled, (state, { payload }) => {
      const { id } = payload;
      state.loadingStateById[id] = LoadingState.succeed;
      state.byId[id] = payload;
    });

    builder.addCase(fetchSingleAssetType.rejected, (state, { payload: error, meta }) => {
      const { assetTypeId } = meta.arg;
      state.loadingStateById[assetTypeId] = LoadingState.failed;
      state.errorById[assetTypeId] = error;
    });
  },
});

export const selectSortedAssetTypes = createSelector(
  (state: RootState) => state.assetTypes,
  ({ ids, byId }) => {
    return ids.map((id) => byId[id]);
  },
);

export const selectAssetTypeById = createSelector(
  (state: RootState) => state.assetTypes,
  (_: any, assetTypeId: string) => assetTypeId,
  ({ byId, loadingStateById, errorById }, assetTypeId) => {
    return {
      assetType: byId[assetTypeId] ?? null,
      isLoaded: loadingStateById[assetTypeId] === LoadingState.succeed,
      error: errorById[assetTypeId],
    };
  },
);

export default assetTypesSlice.reducer;
