import AppConfig from 'AppConfig';
import { AssetType } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeAssetType } from '../deserializers';

export default async function getAssetType(assetTypeId: string): Promise<AssetType> {
  const response = await http.get(
    `${AppConfig.assetsAttributesApiUrl}/asset_types/${assetTypeId}?include=attribute_types,permissions`,
  );
  return await handleResponse(response, (json) => {
    return deserializeAssetType(json);
  });
}
