import AppConfig from 'AppConfig';
import { Asset, AssetType } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeAsset } from '../deserializers';

export interface AssetBreadcrumbResponse {
  data: {
    name: string;
  };
  meta: {
    permissions: {
      permissions: {
        delete_asset: boolean;
        read_asset_section_0: boolean;
        update_asset_section_0: boolean;
      };
    };
  };
}

export default async function getAsset(assetType: AssetType, assetId: string): Promise<Asset> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetType.id}/assets/${assetId}?include=permissions`,
  );

  return await handleResponse(response, (assetsJson) => {
    return deserializeAsset(assetsJson, assetType.attributeTypes);
  });
}

export async function getAssetForBreadcrumb(assetTypeId: string, assetId: string): Promise<AssetBreadcrumbResponse> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}?include=permissions`,
  );

  return await handleResponse(response, (assetsJson) => {
    return {
      data: {
        name: assetsJson.data.attributes.asset_attributes.find(
          (attr: { field_name: string; value: any }) => attr.field_name === 'name',
        ).value[0],
      },
      meta: {
        permissions: assetsJson.meta?.permissions,
      },
    };
  });
}
