import _ from 'lodash';
import { AssetType, AssetTypePermissions } from 'types';
import { deserialize } from 'api/serializer';

export function deserializeAssetType(data: Record<string, any>): AssetType {
  const result = deserialize('asset_types', data) as AssetType;

  result.permissions = _.mapKeys(data?.meta?.permissions, (_value, key) => _.camelCase(key)) as AssetTypePermissions;

  return result;
}

export function deserializeAssetTypes(data: Record<string, any>): AssetType[] {
  return deserialize('asset_types', data) as AssetType[];
}
