import AppConfig from 'AppConfig';
import http from 'api/http';
import { AssessmentQuestionnaire } from 'types';
import { handleResponse } from 'api/helpers';
import { deserializeAssessmentQuestionnaires } from '../deserializers';

export default async function getSentAssessmentQuestionnaires(
  assetTypeId: string,
  assetId: string,
  fieldName: string,
): Promise<AssessmentQuestionnaire[]> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}/assessment_questionnaires?field_name=${fieldName}`,
  );

  return await handleResponse(response, (json) => {
    return deserializeAssessmentQuestionnaires(json, assetId);
  });
}
