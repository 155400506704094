import qs from 'qs';
import AppConfig from 'AppConfig';
import { AssetList, AttributeType } from 'types';
import http from 'api/http';
import { formatPaginationLinks, handleResponse } from 'api/helpers';
import { deserializeAssets } from '../deserializers';

function deserializeAssetList(assetsJson: any, attributeTypes: AttributeType[]): AssetList {
  const { meta, links } = assetsJson;
  const assets = deserializeAssets(assetsJson, attributeTypes);

  return {
    totalCount: meta.count,
    links: formatPaginationLinks(links),
    objects: assets,
  };
}

export interface AssetListOptions {
  page: {
    size: number;
    number?: number;
  };
  sort?: string;
  search?: string;
}

export const buildGetAssetsUrl = (assetTypeId: string, options: AssetListOptions): string => {
  const { page, sort = 'name', search = '' } = options;
  const urlParameters: any = { page: { number: page?.number ?? 1, size: page.size } };
  const searchTerm = search?.trim();

  if (sort) {
    urlParameters.sort = sort;
  }

  if (searchTerm) {
    urlParameters.filter = {
      query: searchTerm,
    };
  }

  return `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets?${qs.stringify(urlParameters)}`;
};

export default async function getAssets(attributeTypes: AttributeType[], url: string): Promise<AssetList> {
  const response = await http.get(url);

  return await handleResponse(response, (assetsJson) => {
    return deserializeAssetList(assetsJson, attributeTypes);
  });
}
