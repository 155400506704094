import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { AttachmentsList } from 'types/Attachments';
import { deserializeAttachments } from './deserializers';

export default async function getAttachments(url: string): Promise<AttachmentsList> {
  const response = await http.get(url);

  return await handleResponse(response, (json) => {
    return deserializeAttachments(json);
  });
}
