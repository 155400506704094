import { handleResponse } from 'api/helpers';
import AppConfig from 'AppConfig';
import http from 'api/http';

export default async function getGlobalNavBar(orgId: string): Promise<any> {
  const response = await http.get(`${AppConfig.launchpadApiUrl}/global_nav_bar?org_id=${orgId}`);

  return await handleResponse(response, (json) => {
    return json.data;
  });
}
