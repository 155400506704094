import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import qs from 'qs';
import { TargetRelationship } from 'types/Relationships';

export default async function deleteAssetRelationship(relationship: {
  objectTypeId: string;
  objectId: string;
  adHocFieldName: string;
  labelFieldName: string;
  target: TargetRelationship;
}): Promise<void> {
  const { objectTypeId: assetTypeId, objectId: assetId, adHocFieldName, labelFieldName, target } = relationship;
  const { object_id, object_container_id } = target;

  const queryParams = {
    field_name: adHocFieldName,
    target_object_type_id: object_container_id,
    target_object_id: object_id,
    label_field_name: labelFieldName,
  };

  const response = await http.delete(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}/relationships?${qs.stringify(
      queryParams,
    )}`,
  );

  return await handleResponse(response, () => {
    return;
  });
}
