import _ from 'lodash';
import { AssetRecord, AssetPermissions, AttributeType } from 'types';
import { deserialize } from 'api/serializer';
import { postProcessAttributes } from 'api/helpers';
import { RecordRelationship } from 'types/Relationships';

export function deserializeRecord(data: Record<string, any>, attributeTypes: AttributeType[]): AssetRecord {
  const result = deserialize('records', data) as Record<string, any>;

  result.recordAttributes = postProcessAttributes(result.recordAttributes, attributeTypes);
  result.permissions = _.mapKeys(data?.meta?.permissions, (_value, key) => _.camelCase(key)) as AssetPermissions;

  result.name = result.recordAttributes['name'];

  return result as AssetRecord;
}

export function deserializeRecords(data: Record<string, any>, attributeTypes: AttributeType[]): AssetRecord[] {
  const records = deserialize('records', data);

  records.forEach((record: Record<string, any>) => {
    record.recordAttributes = postProcessAttributes(record.recordAttributes, attributeTypes);
    record.name = record.recordAttributes['name'];
  });

  return records as AssetRecord[];
}

export function deserializeRecordRelationships(
  data: Record<string, any>,
  context: { adHocAttributeType: AttributeType },
): RecordRelationship[] {
  const { adHocAttributeType } = context;
  const relationships = deserialize('record_relationships', data) as RecordRelationship[];

  const labelsByFieldName = _.keyBy(adHocAttributeType?.typeOptions?.labels ?? [], (label) => label.fieldName);

  return relationships.map((relationship) => ({
    ...relationship,
    fieldName: adHocAttributeType.fieldName,
    label: labelsByFieldName[relationship.label.fieldName],
  }));
}
