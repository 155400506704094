import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import qs from 'qs';
import { TargetRelationship } from 'types/Relationships';

export default async function deleteRecordRelationship(relationship: {
  objectTypeId: string;
  objectId: string;
  adHocFieldName: string;
  labelFieldName: string;
  target: TargetRelationship;
}): Promise<void> {
  const { objectTypeId: recordTypeId, objectId: recordId, adHocFieldName, labelFieldName, target } = relationship;

  const queryParams = {
    field_name: adHocFieldName,
    target_object_type_id: target.object_container_id,
    target_object_id: target.object_id,
    label_field_name: labelFieldName,
  };

  const response = await http.delete(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordTypeId}/records/${recordId}/relationships?${qs.stringify(
      queryParams,
    )}`,
  );

  return await handleResponse(response, () => {
    return;
  });
}
