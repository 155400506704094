import JSONAPISerializer from 'json-api-serializer';

export default function register(serializer: JSONAPISerializer): void {
  serializer.register('statuses', {
    relationships: {
      events: {
        type: 'events',
      },
    },
  });

  serializer.register('workflow_statuses', {
    relationships: {
      events: {
        type: 'events',
      },
    },
    afterDeserialize: (data: Record<string, any>) => {
      if (data.events) {
        data.events = data.events.sort((eventA: any, eventB: any) => Number(eventA.position) - Number(eventB.position));
      }

      return data;
    },
  });

  serializer.register('workflow', {
    relationships: {
      statuses: {
        type: 'workflow_statuses',
      },
    },
  });
}
