import { deserialize } from 'api/serializer';
import { GeneratedReport, ImpactReport, TargetedImpactReport } from 'types/ImpactReports';

export function deserializeImpactReports(data: Record<string, any>): ImpactReport[] {
  const reports = deserialize('impact_reports', data);

  return reports as ImpactReport[];
}

export function deserializeImpactReportTargets(data: Record<string, any>): TargetedImpactReport[] {
  const reports = deserialize('impact_report_targets', data);

  return reports as TargetedImpactReport[];
}

export function deserializeGeneratedReport(data: Record<string, any>): GeneratedReport {
  const reports = deserialize('generated_reports', data);

  return reports as GeneratedReport;
}
