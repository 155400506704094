import { addFieldNameToSectionAttributeTypes } from 'api/helpers';
import JSONAPISerializer from 'json-api-serializer';
import { AssetType, CustomerObjectContainerType, Section } from 'types';

export const TEMPORARY_METADATA_SECTION_ID = 999;

export default function register(serializer: JSONAPISerializer): void {
  serializer.register('asset_types', {
    relationships: {
      attributeTypes: {
        type: 'attribute_types',
      },
    },
    afterDeserialize: (data: AssetType) => {
      data.typeName = CustomerObjectContainerType.ASSET_TYPES;

      if (data.sections) {
        const metaDataItems = data.attributeTypes
          .filter((type) => type.isMetadata && type.fieldName !== 'metadata.workflow_status')
          .map((type) => ({ id: type.id, type: 'attribute_types' }));

        // SNP-1535: Temporary section until activity logging service is created.
        data.sections = [
          ...data.sections.filter((section: any) => section.items.length > 0),
          { id: TEMPORARY_METADATA_SECTION_ID, name: 'Additional Information', items: metaDataItems },
        ] as Section[];

        addFieldNameToSectionAttributeTypes(data);
      }

      return data;
    },
  });

  serializer.register('attribute_types', {});
}
