import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import { GeneratedReport } from 'types/ImpactReports';
import { deserializeGeneratedReport } from '../deserializers';

export default async function generateImpactReport(reportId: string, orgId: string): Promise<GeneratedReport> {
  const response = await http.get(`${AppConfig.apiProxyUrl}/orgs/${orgId}/impact_report_targets/${reportId}/generate`);

  return await handleResponse(response, (generatedReportsJson) => {
    return deserializeGeneratedReport(generatedReportsJson);
  });
}
