import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import Card from '@paprika/card';
import ErrorNotificationCard from '../ErrorNotificationCard';
import { ForbiddenError, HttpErrorResponse, HttpStatusCode } from 'types';
import RedirectAs404Route from '../RedirectAs404';
import ForbiddenErrorPage from '../ForbiddenErrorPage';

import './ErrorNotificationFallback.scss';

export default function ErrorNotificationFallback({ error }: FallbackProps): JSX.Element {
  if (error instanceof ForbiddenError) {
    return <ForbiddenErrorPage />;
  }

  if (error instanceof HttpErrorResponse) {
    const status = (error as HttpErrorResponse).response.status;

    if (status === HttpStatusCode.NOT_FOUND) {
      return <RedirectAs404Route />;
    }

    if (status === HttpStatusCode.FORBIDDEN) {
      return <ForbiddenErrorPage />;
    }
  }

  return (
    <Card className="error-card">
      <ErrorNotificationCard />
    </Card>
  );
}
