import React from 'react';
import useI18n from '@paprika/l10n/lib/useI18n';
import SanitizedText from 'components/common/SanitizedText';
import ErrorPage from './ErrorPage';

export default function Global404Page(): JSX.Element {
  const I18n = useI18n();

  return (
    <ErrorPage
      heading={I18n.t('error_page.page_not_found.heading')}
      body={
        <SanitizedText
          text={I18n.t('error_page.page_not_found.body', {
            systemHomepage: '/',
            contactSupportTeam: I18n.t('links.support_url'),
          })}
        />
      }
    />
  );
}
