import React from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_APP } from './constants';
import { convertCategoriesToUrl, getAppName, getCategoriesFromUrl, parseUrlParameters } from './helpers';

export interface UseAssetTypeCategoriesRoutingResult {
  appName: string;
  assetTypeCategories: string[];
  isLoaded: boolean;
}

export function useAssetTypeCategoryRouting(): UseAssetTypeCategoriesRoutingResult {
  const history = useHistory();
  const urlParameters = parseUrlParameters(history.location.search);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const categoriesRef = React.useRef<string[]>(getCategoriesFromUrl(urlParameters));

  React.useEffect(() => {
    const listener = history.listen((location) => {
      const searchParams = parseUrlParameters(location.search);
      const newCategories = getCategoriesFromUrl(searchParams);
      const previousCategories = categoriesRef.current;

      categoriesRef.current =
        newCategories.length !== 0
          ? newCategories
          : previousCategories.length > 0
          ? previousCategories
          : DEFAULT_APP.categories;

      setIsLoaded(true);
      const newSeachUrl = convertCategoriesToUrl(searchParams, categoriesRef.current);

      if (location.search !== `?${newSeachUrl}`) {
        history.replace({ search: newSeachUrl, hash: location.hash });
      }
    });

    // history.listen returns a function to unregister the given event listener.
    // returning it in this useEffect hook will clean up this listener when the component unmounts.
    return listener;

    // Setting dependencies to empty array since we only want the listener to be registered once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appName = getAppName(history.location.search);

  return {
    isLoaded,
    appName,
    assetTypeCategories: categoriesRef.current,
  };
}
