import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';

export default async function deleteAsset(assetTypeId: string, assetId: string): Promise<void> {
  const response = await http.delete(`${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}`);

  return await handleResponse(response, (assetsJson) => {
    return;
  });
}
