import React from 'react';
import GlobalNavigation, {
  AppSwitcherSidePanel,
  CurrentApp,
  KeyboardShortcutPanel,
  HelperWithPendo,
  Logo,
  User,
  LaunchpadLink,
} from '@acl-services/sriracha-global-navigation';
import useI18n from '@paprika/l10n/lib/useI18n';
import * as api from 'api';
import { getAppName } from 'hooks/useAssetTypeCategories';

const helpDocUrl = 'https://www.wegalvanize.com'; // TODO: replace when helpdocs are available

export default function GlobalNav({ orgId }: { orgId: string }): JSX.Element {
  const I18n = useI18n();
  const [navData, setNavData] = React.useState<any>();

  React.useEffect(() => {
    async function fetchGlobalNav(): Promise<any> {
      const data = await api.getGlobalNavBar(orgId);
      setNavData(data);
    }

    fetchGlobalNav();
  }, [orgId]);

  const currentOrgName = navData?.appSwitcherProps.organizations.find(
    (organization: any) => organization.id === navData?.appSwitcherProps.initialOrganizationId,
  )?.name;

  const appName = getAppName(window.location.search);

  return (
    <div className="sriracha">
      {navData !== undefined ? (
        <GlobalNavigation
          isDiligentBrand={navData.isDiligentBrand}
          hasOrgNameOnTop={navData.hasOrgNameOnTop}
          zIndex={3}
          mainContentSelector="main"
        >
          <LaunchpadLink launchpadUrl={navData.links.launchpad} orgName={currentOrgName} />
          {/* Remove Logo and CurrentApp once hasOrgNameOnTop is flippered */}
          <Logo launchpadUrl={navData.links.launchpad} />
          <CurrentApp
            appId={appName}
            appModuleName={I18n.t([`app_name.${appName}`, `app_name.default`])}
            appUrl={window.location.origin}
          />
          <HelperWithPendo helpDocUrl={helpDocUrl} supportUrl={navData.links.support} />
          <User
            customerName={navData.customerName}
            username={navData.userFullName}
            userProfileUrl={navData.links.profile}
            logoutUrl={navData.links.logout}
          />

          <AppSwitcherSidePanel {...navData.appSwitcherProps} />
          <KeyboardShortcutPanel />
        </GlobalNavigation>
      ) : (
        <GlobalNavigation mainContentSelector="main" />
      )}
    </div>
  );
}
