import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';
import { AvailableQuestionnaire, LoadingState } from 'types';
import * as api from 'api';
import { RootState } from 'store';

export interface AvailableQuestionnairesState {
  questionnairesByAssetTypeId: Record<string, AvailableQuestionnaire[]>;
  errorByAssetTypeId: Record<string, any>;
  loadingByAssetTypeId: Record<string, LoadingState>;
}

const initialState: AvailableQuestionnairesState = {
  questionnairesByAssetTypeId: {},
  errorByAssetTypeId: {},
  loadingByAssetTypeId: {},
};

export const fetchAvailableQuestionnaires = createAsyncThunk(
  'availableQuestionnaires/fetchAvailableQuestionnaires',
  async ({ assetId, assetTypeId }: { assetId: string; assetTypeId: string }, { rejectWithValue }) => {
    try {
      return await api.getAvailableAssessmentQuestionnaires(assetId, assetTypeId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ assetTypeId }, { getState }) => {
      const { availableQuestionnaires } = getState() as RootState;
      const fetchStatus = availableQuestionnaires.loadingByAssetTypeId[assetTypeId];
      if (fetchStatus === LoadingState.loading || fetchStatus === LoadingState.succeed) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

const questionnairesSlice: Slice<AvailableQuestionnairesState> = createSlice({
  name: 'availableQuestionnaires',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableQuestionnaires.pending, (state, { meta }) => {
      const { assetTypeId } = meta.arg;

      state.loadingByAssetTypeId[assetTypeId] = LoadingState.loading;
      state.errorByAssetTypeId[assetTypeId] = null;
    });

    builder.addCase(fetchAvailableQuestionnaires.fulfilled, (state, { payload: questionnaires, meta }) => {
      const { assetTypeId } = meta.arg;

      state.loadingByAssetTypeId[assetTypeId] = LoadingState.succeed;
      state.questionnairesByAssetTypeId[assetTypeId] = questionnaires;
    });

    builder.addCase(fetchAvailableQuestionnaires.rejected, (state, { payload: error, meta }) => {
      const { assetTypeId } = meta.arg;

      state.loadingByAssetTypeId[assetTypeId] = LoadingState.failed;
      state.errorByAssetTypeId[assetTypeId] = error;
    });
  },
});

export const selectAvailableQuestionnaires = createSelector(
  (state: RootState) => state.availableQuestionnaires,
  (_: any, assetTypeId: string) => assetTypeId,
  ({ errorByAssetTypeId, loadingByAssetTypeId, questionnairesByAssetTypeId }, assetTypeId) => {
    return {
      error: errorByAssetTypeId[assetTypeId],
      loading: loadingByAssetTypeId[assetTypeId] ?? LoadingState.loading,
      questionnaires: questionnairesByAssetTypeId[assetTypeId] ?? [],
    };
  },
);

export default questionnairesSlice.reducer;
