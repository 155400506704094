import _ from 'lodash';
import AppConfig from 'AppConfig';
import { Group, GroupsById } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { useQuery, UseQueryResult } from 'react-query';
import { useSession } from 'providers/SessionProvider';

export async function getGroups(orgId: string): Promise<Group[]> {
  const response = await http.get(`${AppConfig.authyApiUrl}/groups?org_id=${orgId}`);

  return await handleResponse(response, (json) => json.data as Group[]);
}

export const selectGroupsDictionary = (groups: Group[]): GroupsById => _.keyBy(groups, (group) => group.id);
export const selectSortedGroups = (groups: Group[]): Group[] => _.sortBy(groups, (group) => group.name);

export function useGroupsQuery<T>(selector?: (groups: Group[]) => T): UseQueryResult<T> {
  const session = useSession();

  return useQuery(['groups', session.org_id], () => getGroups(session.org_id), {
    cacheTime: Infinity,
    staleTime: Infinity,
    select: selector,
  });
}
