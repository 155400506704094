import { Workflow, WorkflowStatus } from 'types';
import { deserialize } from 'api/serializer';

export function deserializeWorkflow(data: Record<string, any>): Workflow {
  const workflow: Workflow = deserialize('workflow', data) as Workflow;

  workflow.statuses.sort((statusA, statusB) => Number(statusA.position) - Number(statusB.position));

  return workflow;
}

export function deserializeWorkflowStatus(data: Record<string, any>): WorkflowStatus {
  const status: WorkflowStatus = deserialize('statuses', data) as WorkflowStatus;

  if (!status.events) {
    status.events = [];
  }

  status.events.sort((eventA, eventB) => Number(eventA.position) - Number(eventB.position));

  return status;
}
