import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000, // 60s
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: process.env.REACT_APP_DISABLE_QUERY_RETRY === 'true' ? false : 3,
    },
  },
});

export default queryClient;
