import { deserialize } from 'api/serializer';
import { Attachment, AttachmentDownloadUrl, AttachmentsList } from 'types/Attachments';

export function deserializeAttachments(data: Record<string, any>): AttachmentsList {
  const attachments = deserialize('attachments', data) as Attachment[];
  return {
    attachments,
    links: data.links,
  };
}

export function deserializeAttachmentDownloadUrl(data: Record<string, any>): AttachmentDownloadUrl {
  return deserialize('attachmentDownloadUrl', data) as AttachmentDownloadUrl;
}
