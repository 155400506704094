import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Location } from 'history';

function RedirectAs404({ location }: { location: Location }) {
  return <Redirect to={{ ...location, state: { is404: true } }} />;
}

export default function RedirectAs404Route(): JSX.Element {
  return <Route path="*" component={RedirectAs404} />;
}
