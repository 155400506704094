import qs from 'qs';
import AppConfig from 'AppConfig';
import http from 'api/http';
import { AssessmentQuestionnaireResponse } from 'types';
import { handleResponse } from 'api/helpers';
import { deserializeAssessmentQuestionnaireResponses } from '../deserializers';

interface AssessmentQuestionnaireOptions {
  filter?: { 'metadata.record_id': string };
  fields?: string[];
}

const getParameters = (options?: AssessmentQuestionnaireOptions): string => {
  const urlParameters: Record<string, any> = {
    include: 'rows',
  };

  if (options?.filter) {
    urlParameters.filter = options.filter;
  }

  if (options?.fields?.length) {
    urlParameters.fields = options.fields.join(',');
  }

  return qs.stringify(urlParameters);
};

export default async function getAssessmentQuestionnaireResponses(
  assetTypeId: string,
  assetId: string,
  questionnaireTableId: string,
  options?: AssessmentQuestionnaireOptions,
): Promise<AssessmentQuestionnaireResponse[]> {
  const url = `${
    AppConfig.assetsInventoryApiUrl
  }/asset_types/${assetTypeId}/assets/${assetId}/assessment_questionnaire_tables/${questionnaireTableId}?${getParameters(
    options,
  )}`;
  const response = await http.get(url);

  return await handleResponse(response, (json) => {
    return deserializeAssessmentQuestionnaireResponses(json);
  });
}
