import React from 'react';
import { Route, useHistory } from 'react-router-dom';

interface CustomLocationState {
  is404: boolean;
}

interface Props {
  children: React.ReactNode;
  Fallback404Component: React.ComponentType;
}

export default function RouteWithFallback({ children, Fallback404Component }: Props): JSX.Element {
  const history = useHistory();

  React.useEffect(() => {
    // Reset history state when the page is loaded, in order to drop the .is404 flag
    history.replace({
      ...history.location,
      state: undefined,
    });
  }, [history]);

  return (
    <Route
      render={({ location }) => {
        return location.state && (location.state as CustomLocationState).is404 ? <Fallback404Component /> : children;
      }}
    />
  );
}
