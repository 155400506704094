import AppConfig from 'AppConfig';
import { AssetAttributes, AssetRecord, AssetRecordType } from 'types';
import http from 'api/http';
import { convertAttributesToServerFormat, handleResponse } from 'api/helpers';
import { deserializeRecord } from '../deserializers';

export default async function createRecord(
  recordType: AssetRecordType,
  attributes: AssetAttributes,
): Promise<AssetRecord | null> {
  const body = {
    data: {
      type: 'records',
      attributes: {
        record_attributes: convertAttributesToServerFormat(attributes, recordType.attributeTypes),
      },
    },
  };

  const response = await http.post(`${AppConfig.assetsInventoryApiUrl}/record_types/${recordType.id}/records`, body);

  return await handleResponse(response, (recordsJson) => {
    if (recordsJson === null) {
      return null;
    }

    return deserializeRecord(recordsJson, recordType.attributeTypes);
  });
}
