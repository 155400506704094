import AppConfig from 'AppConfig';
import { AssetRecordType } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeRecordType } from '../deserializers';

export default async function getRecordType(recordTypeId: string): Promise<AssetRecordType> {
  const response = await http.get(
    `${AppConfig.assetsAttributesApiUrl}/record_types/${recordTypeId}?include=attribute_types,permissions`,
  );
  return await handleResponse(response, (json) => {
    return deserializeRecordType(json);
  });
}
