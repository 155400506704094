import _ from 'lodash';
import AppConfig from 'AppConfig';
import { User, UsersById } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { useQuery, UseQueryResult } from 'react-query';
import { useSession } from 'providers/SessionProvider';

export async function getUsers(orgId: string): Promise<User[]> {
  const response = await http.get(`${AppConfig.authyApiUrl}/users?org_id=${orgId}`);

  return await handleResponse(response, (json) => json.data as User[]);
}

export const selectUsersDictionary = (users: User[]): UsersById => _.keyBy(users, (user) => user.uid);
export const selectSortedUsers = (users: User[]): User[] => _.sortBy(users, (user) => user.name);
export const selectEnabledRegularSortedUsers = (users: User[]): User[] =>
  _.sortBy(
    users.filter((user) => user.userType === 'user' && user.enabled),
    (user) => user.name,
  );

export function useUsersQuery<T>(selector?: (users: User[]) => T): UseQueryResult<T> {
  const session = useSession();

  return useQuery(['users', session.org_id], () => getUsers(session.org_id), {
    cacheTime: Infinity,
    staleTime: Infinity,
    select: selector,
  });
}
