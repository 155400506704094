import qs from 'qs';
import AppConfig from 'AppConfig';
import http from 'api/http';
import { AssessmentQuestionnaireField } from 'types';
import { handleResponse } from 'api/helpers';
import { deserializeAssessmentQuestionnaireFields } from '../deserializers';

export default async function getAssessmentQuestionnaireFields(
  assetTypeId: string,
  assetId: string,
  questionnaireTableId: string,
): Promise<AssessmentQuestionnaireField[]> {
  const url = `${
    AppConfig.assetsInventoryApiUrl
  }/asset_types/${assetTypeId}/assets/${assetId}/assessment_questionnaire_tables/${questionnaireTableId}?${qs.stringify(
    {
      include: 'field_defs',
    },
  )}`;
  const response = await http.get(url);

  return await handleResponse(response, (json) => {
    return deserializeAssessmentQuestionnaireFields(json);
  });
}
