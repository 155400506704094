import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import { TargetRelationship } from 'types/Relationships';

export default async function createRecordRelationship(relationship: {
  objectTypeId: string;
  objectId: string;
  adHocFieldName: string;
  labelFieldName: string;
  target: TargetRelationship;
}): Promise<void> {
  const { objectTypeId: recordTypeId, objectId: recordId, adHocFieldName, labelFieldName, target } = relationship;

  const body = {
    data: {
      type: 'record_relationships',
      attributes: {
        field_name: adHocFieldName,
        target,
        label: {
          field_name: labelFieldName,
        },
      },
    },
  };

  const response = await http.post(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordTypeId}/records/${recordId}/relationships?field_name=${adHocFieldName}`,
    body,
  );

  return await handleResponse(response, () => {
    return;
  });
}
