import { Asset, AttributeType } from 'types';
import { AssetRecord } from './Records';

export enum CustomerObjectTypeName {
  ASSETS = 'assets',
  RECORDS = 'records',
}

export enum CustomerObjectContainerType {
  ASSET_TYPES = 'asset_types',
  RECORD_TYPES = 'record_types',
}

export interface CustomerObjectDescription {
  id: string;
  type: CustomerObjectTypeName;
  containerId: string;
  containerType: CustomerObjectContainerType;
  workflowStatusId?: string;
  [key: string]: any;
}

export enum SectionItemType {
  ATTRIBUTE_TYPE = 'attribute_types',
}

export interface SectionItem {
  id: string;
  fieldName: string;
  type: SectionItemType;
}

export interface Section {
  id: number;
  name: string;
  items: SectionItem[];
}

export type CustomerObject = Asset | AssetRecord;

export interface CustomerObjectTypeDescription {
  id: string;
  displayName: string;
}

export interface CustomerObjectType {
  id: string;
  typeName: CustomerObjectContainerType;
  attributeTypes: AttributeType[];
  name: string;
  orgId: string;
  symbolicName: string;
  description: string;
  displayName: string;
  workflowId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  isArchived: boolean;
  sections: Section[];
}
