import React from 'react';
import { sanitize } from 'dompurify';

interface SanitizedTextProps {
  text: string;
  className?: string;
}

function SanitizedText({ text, className }: SanitizedTextProps): JSX.Element {
  const __html = sanitize(text, { ADD_ATTR: ['target'] });
  return <div className={className} data-qa-anchor="sanitized-text" dangerouslySetInnerHTML={{ __html }} />;
}

export default SanitizedText;
