import AppConfig from 'AppConfig';
import qs from 'qs';
import { Workflow } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeWorkflow } from '../deserializers';

export default async function getWorkflow(workflowId: string): Promise<Workflow> {
  const url = `${AppConfig.workflowsApiUrl}/workflows/${workflowId}?${qs.stringify({
    include: 'statuses',
  })}`;

  const response = await http.get(url);

  return await handleResponse(response, (json) => {
    return deserializeWorkflow(json);
  });
}
