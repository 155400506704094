import AppConfig from 'AppConfig';
import { AssetRecord, AssetAttributes, AssetRecordType } from 'types';
import http from 'api/http';
import { convertAttributesToServerFormat, handleResponse } from 'api/helpers';
import { deserializeRecord } from '../deserializers';

export default async function updateRecord(
  recordType: AssetRecordType,
  recordId: string,
  updatedAttributes: AssetAttributes,
): Promise<AssetRecord> {
  const body: any = {
    data: {
      type: 'records',
      attributes: {
        record_attributes: convertAttributesToServerFormat(updatedAttributes, recordType.attributeTypes),
      },
    },
  };

  const response = await http.patch(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordType.id}/records/${recordId}`,
    body,
  );

  return await handleResponse(response, (recordsJson) => {
    return deserializeRecord(recordsJson, recordType.attributeTypes);
  });
}
