import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import { TargetRelationship } from 'types/Relationships';

export default async function createAssetRelationship(relationship: {
  objectTypeId: string;
  objectId: string;
  adHocFieldName: string;
  labelFieldName: string;
  target: TargetRelationship;
}): Promise<void> {
  const { objectTypeId: assetTypeId, objectId: assetId, adHocFieldName, labelFieldName, target } = relationship;

  const body = {
    data: {
      type: 'asset_relationships',
      attributes: {
        field_name: adHocFieldName,
        target,
        label: {
          field_name: labelFieldName,
        },
      },
    },
  };

  const response = await http.post(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}/relationships?field_name=${adHocFieldName}`,
    body,
  );

  return await handleResponse(response, () => {
    return;
  });
}
