/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import History from '@acl-services/wasabicons/lib/History';
import { useLocation, Link, useHistory } from 'react-router-dom';
import Button from '@paprika/button';
import Heading from '@paprika/heading';
import SideNavigation from '@paprika/side-navigation';
import useI18n from '@paprika/l10n/lib/useI18n';
import { useSession } from 'providers/SessionProvider';
import { useTypedSelector } from 'store';
import { selectSortedAssetTypes } from 'store/slices/assetTypes/assetTypes';
import { selectSortedRecordTypes } from 'store/slices/recordTypes/recordTypes';
import { Flippers } from 'AppConfig';

import './SideNav.scss';
import { useAssetTypeCategories } from 'hooks/useAssetTypeCategories';

export default function SideNav(): JSX.Element {
  const history = useHistory();
  const session = useSession();
  const { assetTypeCategories, appName } = useAssetTypeCategories();

  const pathMatch = useLocation().pathname.match(/_types\/([0-9]+)/);
  const objectTypeId = pathMatch ? pathMatch[1] : null;

  const assetTypes = useTypedSelector((state) => selectSortedAssetTypes(state));
  const recordTypes = useTypedSelector((state) => selectSortedRecordTypes(state));

  const I18n = useI18n();

  const assetTypeCategoriesSearchParam = `category=${assetTypeCategories.join(',')}`;
  const canReadActivityLogs = session.permissions.read_audit_trail;

  return (
    <SideNavigation
      header={I18n.t([`side_navigation.objects_heading.${appName}`, `side_navigation.objects_heading.default`])}
      offsetY={40}
      data-qa-anchor="asset-inventory-side-nav"
      className="asset-inventory-side-nav"
    >
      {assetTypes.map((assetType) => (
        <SideNavigation.Item
          key={assetType.id}
          as={Link}
          component={Button.Link}
          to={`/asset_types/${assetType.id}/assets?${assetTypeCategoriesSearchParam}`}
          isCurrent={assetType.id === objectTypeId}
          href=""
          onKeyUp={(e: KeyboardEvent) => e.key === ' ' && history.push(`/asset_types/${assetType.id}/assets`)}
        >
          {assetType.displayName}
        </SideNavigation.Item>
      ))}

      <Heading className="asset-inventory-side-nav__records-heading" level={3} displayLevel={5}>
        {I18n.t('side_navigation.records_heading')}
      </Heading>

      {recordTypes.map((recordType) => (
        <SideNavigation.Item
          key={recordType.id}
          as={Link}
          component={Button.Link}
          to={`/record_types/${recordType.id}/records?${assetTypeCategoriesSearchParam}`}
          isCurrent={recordType.id === objectTypeId}
          href=""
          onKeyUp={(e: KeyboardEvent) => e.key === ' ' && history.push(`/record_types/${recordType.id}/records`)}
        >
          {recordType.displayName}
        </SideNavigation.Item>
      ))}

      {Flippers.activities && canReadActivityLogs && (
        // TODO: Fix SideNavigation .d.ts file
        // @ts-ignore
        <SideNavigation.Footer>
          <SideNavigation.Item
            as={Link}
            component={Button.Link}
            to={`/activities?${assetTypeCategoriesSearchParam}`}
            href=""
            onKeyUp={(e: KeyboardEvent) => e.key === ' ' && history.push(`/activities`)}
          >
            <History />
            {I18n.t('activity_log')}
          </SideNavigation.Item>
          {/* @ts-ignore */}
        </SideNavigation.Footer>
      )}
    </SideNavigation>
  );
}
