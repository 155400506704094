import { createSlice, Slice } from '@reduxjs/toolkit';

interface GlobalNotificationSliceState {
  type: string | null;
  timestamp: number | null;
  meta: { [param: string]: any };
}

const initialState: GlobalNotificationSliceState = {
  type: null,
  timestamp: null,
  meta: {},
};

const globalNotificationSlice: Slice = createSlice({
  name: 'globalNotification',
  initialState,
  reducers: {
    show: (state, action) => {
      return {
        type: action.payload.type,
        meta: action.payload.meta,
        timestamp: Date.now(),
      };
    },
    close: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const { show, close } = globalNotificationSlice.actions;

export default globalNotificationSlice.reducer;
