import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';
import { LoadingState, Workflow } from 'types';
import * as api from 'api';
import { RootState } from 'store';

export interface WorkflowState {
  [workflowId: string]: {
    workflow: Workflow | null;
    error: any;
    loadingState: LoadingState;
  };
}

const initialState: WorkflowState = {};

export const fetchWorkflow = createAsyncThunk(
  'workflow/fetchWorkflow',
  async ({ workflowId }: { workflowId: string }, { rejectWithValue }) => {
    try {
      return await api.getWorkflow(workflowId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ workflowId }, { getState }) => {
      const { workflows } = getState() as RootState;
      const fetchStatus = workflows[workflowId]?.loadingState ?? LoadingState.idle;
      if (fetchStatus === LoadingState.loading || fetchStatus === LoadingState.succeed) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

const workflowSlice: Slice<WorkflowState> = createSlice({
  name: 'workflow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkflow.pending, (state, { meta }) => {
      const { workflowId } = meta.arg;

      state[workflowId] = {
        error: null,
        loadingState: LoadingState.loading,
        workflow: null,
      };
    });

    builder.addCase(fetchWorkflow.fulfilled, (state, { payload: workflow }) => {
      state[workflow.id] = {
        error: null,
        loadingState: LoadingState.succeed,
        workflow,
      };
    });

    builder.addCase(fetchWorkflow.rejected, (state, { payload: error, meta }) => {
      const { workflowId } = meta.arg;

      state[workflowId] = {
        error: error,
        loadingState: LoadingState.failed,
        workflow: null,
      };
    });
  },
});

export const selectWorkflowById = createSelector(
  (state: RootState, workflowId: string | undefined) => (workflowId ? state.workflows[workflowId] : undefined),
  (stateSlice) => {
    return {
      error: stateSlice?.error ?? null,
      loading: stateSlice?.loadingState ?? LoadingState.loading,
      workflow: stateSlice?.workflow ?? null,
    };
  },
);

export default workflowSlice.reducer;
