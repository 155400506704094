import AppConfig from 'AppConfig';
import http from 'api/http';
import { Event, InvokeEventResponse, CustomerObjectDescription } from 'types';
import { handleResponse } from 'api/helpers';
import { deserializeInvokeEventResponse } from '../deserializers';

export default async function invokeEvent(event: Event): Promise<InvokeEventResponse<CustomerObjectDescription>> {
  const body = {
    data: {
      id: event.id,
      type: 'events',
      attributes: {
        invocation_context: event.invocationContext,
      },
    },
  };

  const response = await http.patch(`${AppConfig.assetsInventoryApiUrl}/invoke_event/${event.id}`, body);

  return await handleResponse(response, (json) => {
    return deserializeInvokeEventResponse(json);
  });
}
