import http from 'api/http';
import AppConfig from 'AppConfig';
import { AssetInventorySession } from 'types/Session';

export default async function getSession(): Promise<AssetInventorySession | undefined> {
  const response = await http.get(`${AppConfig.assetsInventoryApiSessionUrl}/session`);

  if (response.ok) {
    return (await response.json()) as AssetInventorySession;
  } else {
    // silently redirect
    // throw new Error(response.statusText);
  }
}
