import { keyBy } from 'lodash';
import AppConfig from 'AppConfig';
import http from 'api/http';
import { CustomerObjectType } from 'types';
import { handleResponse } from 'api/helpers';
import { deserializeImpactReports } from '../deserializers';
import { ImpactReport, ImpactReportTarget, TargetedImpactReport } from 'types/ImpactReports';
import { deserializeImpactReportTargets } from '../deserializers';

async function getImpactReports(orgId: string): Promise<ImpactReport[]> {
  const response = await http.get(`${AppConfig.apiProxyUrl}/orgs/${orgId}/impact_reports`);

  return await handleResponse(response, (impactReportsJson) => {
    return deserializeImpactReports(impactReportsJson);
  });
}

async function getImpactReportTargets(objectType: CustomerObjectType, orgId: string): Promise<ImpactReportTarget[]> {
  const response = await http.get(
    `${AppConfig.apiProxyUrl}/orgs/${orgId}/impact_report_targets/${objectType.typeName}/${objectType.id}`,
  );

  return await handleResponse(response, (targetedReportsJson) => {
    const impactReportTargets = deserializeImpactReportTargets(targetedReportsJson);
    return impactReportTargets.sort(
      (reportA: any, reportB: any) => Number(reportA.position) - Number(reportB.position),
    );
  });
}

export default async function getTargetedImpactReports(
  objectType: CustomerObjectType,
  orgId: string,
): Promise<TargetedImpactReport[]> {
  const [impactReports, impactReportTargets] = await Promise.all([
    getImpactReports(orgId),
    getImpactReportTargets(objectType, orgId),
  ]);
  const impactReportsById = keyBy(impactReports, 'id');
  return impactReportTargets.map((target) => {
    return {
      ...target,
      report: impactReportsById[target.reportId],
    };
  });
}
