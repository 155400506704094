import _ from 'lodash';
import { AssetRecordType, AssetTypePermissions } from 'types';
import { deserialize } from 'api/serializer';

export function deserializeRecordType(data: Record<string, any>): AssetRecordType {
  const result = deserialize('record_types', data) as AssetRecordType;

  result.permissions = _.mapKeys(data?.meta?.permissions, (_value, key) => _.camelCase(key)) as AssetTypePermissions;

  return result;
}

export function deserializeRecordTypes(data: Record<string, any>): AssetRecordType[] {
  return deserialize('record_types', data) as AssetRecordType[];
}
