import JSONAPISerializer from 'json-api-serializer';
import registerAssessmentsSerializers from 'api/handlers/assessments/registerSerializers';
import registerAssetTypesSerializers from 'api/handlers/assetTypes/registerSerializers';
import registerAssetsSerializers from 'api/handlers/assets/registerSerializers';
import registerWorkflowsSerializers from 'api/handlers/workflows/registerSerializers';
import registerEventsSerializers from 'api/handlers/events/registerSerializers';
import registerRecordsSerializers from 'api/handlers/records/registerSerializers';
import registerRecordTypesSerializers from 'api/handlers/recordTypes/registerSerializers';
import registerImpactReportsSerializers from 'api/handlers/impactReports/registerSerializers';
import registerAttachmentsSerializers from 'api/handlers/attachments/registerSerializers';

const serializer = new JSONAPISerializer({
  convertCase: 'snake_case',
  unconvertCase: 'camelCase',
});

registerEventsSerializers(serializer);
registerAssessmentsSerializers(serializer);
registerWorkflowsSerializers(serializer);
registerAssetsSerializers(serializer);
registerAssetTypesSerializers(serializer);
registerRecordsSerializers(serializer);
registerRecordTypesSerializers(serializer);
registerImpactReportsSerializers(serializer);
registerAttachmentsSerializers(serializer);

interface IncludedRelationship {
  type: string;
}

function filterAllowedRelationships(json: Record<string, any>): Record<string, any> {
  if (json.included) {
    json.included = json.included.filter(({ type }: IncludedRelationship) => {
      const hasSchema = !!serializer.schemas[type];

      if (!hasSchema && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')) {
        console.error(`A relationship type: "${type}" does not have a registered serializer`);
      }

      return hasSchema;
    });
  }

  return json;
}

export function deserialize(type: string, json: Record<string, any>): Record<string, any> | Record<string, any>[] {
  return serializer.deserialize<Record<string, any>>(type, filterAllowedRelationships(json));
}

export default serializer;
