import AppConfig from 'AppConfig';
import { AssetType } from 'types';
import qs from 'qs';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeAssetTypes } from '../deserializers';

interface AssetTypeOptions {
  includeAttributeTypes?: boolean;
  assetTypeCategories?: string[];
}

export default async function getAssetTypes(options?: AssetTypeOptions): Promise<AssetType[]> {
  const searchParams = qs.stringify({
    ...(options?.includeAttributeTypes ? { include: 'attribute_types' } : {}),
    ...(options?.assetTypeCategories ? { filter: { category: options.assetTypeCategories.join(',') } } : {}),
  });

  const response = await http.get(`${AppConfig.assetsAttributesApiUrl}/asset_types?${searchParams}`);

  return await handleResponse(response, (json) => {
    return deserializeAssetTypes(json);
  });
}
