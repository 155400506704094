import React from 'react';

interface AssetTypeCategoriesContextData {
  appName: string;
  assetTypeCategories: string[];
}

export const AssetTypeCategoriesContext = React.createContext<AssetTypeCategoriesContextData | null>(null);

export function useAssetTypeCategories(): AssetTypeCategoriesContextData {
  const assetTypeCategoriesData = React.useContext<AssetTypeCategoriesContextData | null>(AssetTypeCategoriesContext);

  if (assetTypeCategoriesData === null) {
    throw new Error('useAssetTypeCategories must be called within a AssetTypeCategoriesContext.Provider');
  }

  return assetTypeCategoriesData;
}
