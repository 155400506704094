import AppConfig from 'AppConfig';
import http from 'api/http';
import { handleResponse } from 'api/helpers';

export default async function deleteRecord(recordTypeId: string, recordId: string): Promise<void> {
  const response = await http.delete(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordTypeId}/records/${recordId}`,
  );

  return await handleResponse(response, (recordsJson) => {
    return;
  });
}
