import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';
import { AssessmentQuestionnaireField, LoadingState } from 'types';
import * as api from 'api';
import { RootState } from 'store';

export interface QuestionnaireFieldsState {
  [questionnaireTableId: string]: {
    fields: AssessmentQuestionnaireField[];
    error: any;
    loading: LoadingState;
  };
}

const name = 'questionnaireFields';

export const initialState: QuestionnaireFieldsState = {};

export const fetchQuestionnaireFields = createAsyncThunk(
  `${name}/fetchQuestionnaireFields`,
  async (
    {
      assetTypeId,
      assetId,
      questionnaireTableId,
    }: {
      assetTypeId: string;
      assetId: string;
      questionnaireTableId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.getAssessmentQuestionnaireFields(assetTypeId, assetId, questionnaireTableId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ questionnaireTableId }, { getState }) => {
      const { questionnaireFields } = getState() as RootState;
      const fetchStatus = questionnaireFields[questionnaireTableId]?.loading ?? LoadingState.idle;

      if (fetchStatus === LoadingState.loading || fetchStatus === LoadingState.succeed) {
        // Already fetched or in progress, don't need to re-fetch
        return false;
      }
    },
  },
);

const questionnaireFieldsSlice: Slice<QuestionnaireFieldsState> = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionnaireFields.pending, (state, { meta }) => {
      const { questionnaireTableId } = meta.arg;

      state[questionnaireTableId] = {
        fields: [],
        error: null,
        loading: LoadingState.loading,
      };
    });

    builder.addCase(fetchQuestionnaireFields.fulfilled, (state, { payload: fields, meta }) => {
      const { questionnaireTableId } = meta.arg;
      const stateSlice = state[questionnaireTableId];

      stateSlice.fields = fields;
      stateSlice.loading = LoadingState.succeed;
    });

    builder.addCase(fetchQuestionnaireFields.rejected, (state, { payload: error, meta }) => {
      const { questionnaireTableId } = meta.arg;
      const stateSlice = state[questionnaireTableId];

      stateSlice.error = error;
      stateSlice.loading = LoadingState.failed;
    });
  },
});

export const selectQuestionnaireFields = createSelector(
  (state: RootState, questionnaireTableId: string) => state.questionnaireFields[questionnaireTableId],
  (stateSlice) => {
    return (
      stateSlice ?? {
        fields: [],
        error: null,
        loading: LoadingState.loading,
      }
    );
  },
);

export default questionnaireFieldsSlice.reducer;
