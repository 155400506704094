import AppConfig from 'AppConfig';
import { AssetRecordType } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeRecordType } from '../deserializers';

export default async function getRecordTypes(options = { includeAttributeTypes: false }): Promise<AssetRecordType[]> {
  const response = await http.get(
    `${AppConfig.assetsAttributesApiUrl}/record_types${
      options.includeAttributeTypes ? '?include=attribute_types' : ''
    }`,
  );

  return await handleResponse(response, (json) => {
    return deserializeRecordType(json);
  });
}
