import { createAsyncThunk, createSelector, createSlice, Slice } from '@reduxjs/toolkit';
import { AssessmentQuestionnaire, LoadingState } from 'types';
import * as api from 'api';
import { RootState } from 'store';
import { fetchQuestionnaireResponses } from '../questionnaireResponses/questionnaireResponses';

export interface QuestionnairesState {
  [assetId: string]: {
    items: AssessmentQuestionnaire[];
    error: any;
    loading: LoadingState;
  };
}

const initialState: QuestionnairesState = {};

export const fetchQuestionnaires = createAsyncThunk(
  'questionnaires/fetchQuestionnaires',
  async (
    {
      assetTypeId,
      assetId,
      fieldName,
    }: { assetTypeId: string; assetId: string; fieldName: string; forceReloadQuestionnaires?: boolean },
    { rejectWithValue },
  ) => {
    try {
      return await api.getSentAssessmentQuestionnaires(assetTypeId, assetId, fieldName);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
  {
    condition: ({ assetId, forceReloadQuestionnaires }, { getState }) => {
      const { questionnaires } = getState() as RootState;
      const fetchStatus = questionnaires[assetId]?.loading ?? LoadingState.idle;
      if (fetchStatus === LoadingState.loading) {
        // Already in progress, don't need to re-fetch
        return false;
      }

      if (forceReloadQuestionnaires) {
        return true;
      }

      if (fetchStatus === LoadingState.succeed) {
        // Already loaded, don't need to re-fetch unless forced to
        return false;
      }
    },
  },
);

const questionnairesSlice: Slice<QuestionnairesState> = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionnaires.pending, (state, { meta }) => {
      const { assetId } = meta.arg;

      state[assetId] = {
        loading: LoadingState.loading,
        error: null,
        items: [],
      };
    });

    builder.addCase(fetchQuestionnaires.fulfilled, (state, { payload: questionnaires, meta }) => {
      const { assetId } = meta.arg;

      state[assetId] = {
        loading: LoadingState.succeed,
        error: null,
        items: questionnaires,
      };
    });

    builder.addCase(fetchQuestionnaires.rejected, (state, { payload: error, meta }) => {
      const { assetId } = meta.arg;

      state[assetId] = {
        loading: LoadingState.failed,
        error: error,
        items: [],
      };
    });

    /**
     * Syncs the `numberOfAssessments` counter with the number of QuestionnaireResponses
     */
    builder.addCase(fetchQuestionnaireResponses.fulfilled, (state, { payload: responses, meta }) => {
      const { assetId, questionnaireTableId } = meta.arg;

      const questionnaire = state[assetId]?.items?.find((item) => item.dataTableId === questionnaireTableId);

      if (questionnaire) {
        questionnaire.numberOfAssessments = responses.length;
      }
    });
  },
});

export const selectQuestionnaires = createSelector(
  (state: RootState, assetId: string) => state.questionnaires[assetId],
  (stateSlice) => {
    return {
      error: stateSlice?.error ?? null,
      loading: stateSlice?.loading ?? LoadingState.loading,
      questionnaires: stateSlice?.items ?? [],
    };
  },
);

export default questionnairesSlice.reducer;
