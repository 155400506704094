import AppConfig from 'AppConfig';
import qs from 'qs';
import { WorkflowStatus } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeWorkflowStatus } from '../deserializers';

export default async function getWorkflowStatus(workflowStatusId: string): Promise<WorkflowStatus> {
  const url = `${AppConfig.workflowsApiUrl}/statuses/${workflowStatusId}?${qs.stringify({
    include: 'events',
  })}`;

  const response = await http.get(url);

  return await handleResponse(response, (json) => {
    return deserializeWorkflowStatus(json);
  });
}
