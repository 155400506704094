import React from 'react';
import useI18n from '@paprika/l10n/lib/useI18n';
import Toast from '@paprika/toast';
import { useTypedSelector } from 'store';
import { NotificationTypes } from 'types';
import SanitizedText from 'components/common/SanitizedText/SanitizedText';

import './GlobalNotificationToast.scss';

const NOTIFICATION_AUTO_CLOSE_DELAY = 10 * 1000; // 10 Seconds

interface NotificationToastProps {
  timestamp: string;
  kind: any;
  children: React.ReactNode;
}

function NotificationToast({ timestamp, kind, children }: NotificationToastProps) {
  return (
    <Toast
      key={timestamp}
      hasCloseButton={true}
      canAutoClose={true}
      autoCloseDelay={NOTIFICATION_AUTO_CLOSE_DELAY}
      isFixed={true}
      kind={kind}
    >
      {children}
    </Toast>
  );
}

export default function GlobalNotificationToast(): JSX.Element | null {
  const I18n = useI18n();
  const { type, meta, timestamp } = useTypedSelector((state) => state.globalNotification);

  if (type === null) {
    return null;
  }

  if (type === NotificationTypes.ASSET_UPDATED_SUCCESS) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.SUCCESS}>
        <div>
          {I18n.t('global_notification.asset_updated.success', {
            assetTypeName: meta.assetTypeName,
          })}
        </div>
      </NotificationToast>
    );
  }

  if (type === NotificationTypes.ASSET_DELETED_SUCCESS) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.SUCCESS}>
        <div>
          {I18n.t('global_notification.asset_deleted.success', {
            assetName: meta.assetName,
            assetTypeName: meta.assetTypeName,
          })}
        </div>
      </NotificationToast>
    );
  }

  if (type === NotificationTypes.CUSTOMER_OBJECT_CREATED_SUCCESS) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.SUCCESS}>
        <div>
          {I18n.t('global_notification.customer_object_created.success', {
            customerObjectName: meta.assetName,
            customerObjectTypeName: meta.assetTypeName,
          })}
        </div>
      </NotificationToast>
    );
  }

  if (type === NotificationTypes.ASSET_UPDATED_CONFLICT_ERROR) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.ERROR}>
        <div className="global-notificaton-toast__title">
          {I18n.t('global_notification.asset_updated.conflict.title')}
        </div>
        <p>{I18n.t('global_notification.asset_updated.conflict.description')}</p>
      </NotificationToast>
    );
  }

  if (type === NotificationTypes.ASSET_DELETED_ERROR) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.ERROR}>
        <div>{I18n.t('global_notification.asset_deleted.error', { assetName: meta.assetName })}</div>
        <SanitizedText text={meta.message} />
      </NotificationToast>
    );
  }

  if (type === NotificationTypes.RECORD_UPDATED_SUCCESS) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.SUCCESS}>
        <div>{I18n.t('global_notification.record_updated.success')}</div>
      </NotificationToast>
    );
  }

  if (type === NotificationTypes.QUESTIONNAIRE_SENT) {
    return (
      <NotificationToast timestamp={timestamp} kind={Toast.types.kind.SUCCESS}>
        <div>{I18n.t('global_notification.questionnaire_sent.success')}</div>
      </NotificationToast>
    );
  }

  return null;
}
