import _ from 'lodash';
import { Asset, AssetPermissions, AttributeType } from 'types';
import { deserialize } from 'api/serializer';
import { postProcessAttributes } from 'api/helpers';
import { AssetRelationship } from 'types/Relationships';

export function deserializeAsset(data: Record<string, any>, attributeTypes: AttributeType[]): Asset {
  const result = deserialize('assets', data) as Record<string, any>;

  result.assetAttributes = postProcessAttributes(result.assetAttributes, attributeTypes);
  result.permissions = _.mapKeys(data?.meta?.permissions, (_value, key) => _.camelCase(key)) as AssetPermissions;

  result.name = result.assetAttributes['name'];

  return result as Asset;
}

export function deserializeAssets(data: Record<string, any>, attributeTypes: AttributeType[]): Asset[] {
  const assets = deserialize('assets', data);

  assets.forEach((asset: Record<string, any>) => {
    asset.assetAttributes = postProcessAttributes(asset.assetAttributes, attributeTypes);
    asset.name = asset.assetAttributes['name'];
  });

  return assets as Asset[];
}

export function deserializeAssetRelationships(
  data: Record<string, any>,
  context: { adHocAttributeType: AttributeType },
): AssetRelationship[] {
  const { adHocAttributeType } = context;
  const relationships = deserialize('asset_relationships', data) as AssetRelationship[];

  const labelsByFieldName = _.keyBy(adHocAttributeType?.typeOptions?.labels ?? [], (label) => label.fieldName);

  return relationships.map((relationship) => ({
    ...relationship,
    fieldName: adHocAttributeType.fieldName,
    label: labelsByFieldName[relationship.label.fieldName],
  }));
}
