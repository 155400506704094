import AppConfig from 'AppConfig';
import { AssetRecord, AssetRecordType } from 'types';
import http from 'api/http';
import { handleResponse } from 'api/helpers';
import { deserializeRecord } from '../deserializers';

export interface RecordBreadcrumbResponse {
  data: {
    name: string;
    parent: {
      id: string;
      object_type_id: string;
    };
  };
  meta: {
    permissions: {
      delete_asset: boolean;
      read_asset_section_0: boolean;
      update_asset_section_0: boolean;
    };
  };
}

export default async function getRecord(recordType: AssetRecordType, recordId: string): Promise<AssetRecord> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordType.id}/records/${recordId}?include=permissions`,
  );

  return await handleResponse(response, (recordsJson) => {
    return deserializeRecord(recordsJson, recordType.attributeTypes);
  });
}

export async function getRecordForBreadcrumb(
  recordTypeId: string,
  recordId: string,
): Promise<RecordBreadcrumbResponse> {
  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/record_types/${recordTypeId}/records/${recordId}`,
  );

  return await handleResponse(response, (recordsJson) => {
    return {
      data: {
        name: recordsJson.data.attributes.record_attributes.find(
          (attr: { field_name: string; value: any }) => attr.field_name === 'name',
        ).value[0],
        parent: recordsJson.data.attributes.record_attributes.find(
          (attr: { field_name: string; value: any }) => attr.field_name === 'parent',
        ).value,
      },
      meta: {
        permissions: recordsJson.meta?.permissions,
      },
    };
  });
}
