import AppConfig from 'AppConfig';
import { Asset, AssetAttributes, AssetType } from 'types';
import http from 'api/http';
import { convertAttributesToServerFormat, handleResponse } from 'api/helpers';
import { deserializeAsset } from '../deserializers';

export default async function createAsset(assetType: AssetType, attributes: AssetAttributes): Promise<Asset | null> {
  const body = {
    data: {
      type: 'assets',
      attributes: {
        asset_attributes: convertAttributesToServerFormat(attributes, assetType.attributeTypes),
      },
    },
  };

  const response = await http.post(`${AppConfig.assetsInventoryApiUrl}/asset_types/${assetType.id}/assets`, body);

  return await handleResponse(response, (assetsJson) => {
    if (assetsJson === null) {
      /**
       * The edge case where
       * a user might have permission to create a new asset
       * but not read any assets (or at least read any assets in their initial workflow status).
       * In this case the result of the create will be 201 Created with an empty body
       */
      return null;
    }

    return deserializeAsset(assetsJson, assetType.attributeTypes);
  });
}
