import { combineReducers } from '@reduxjs/toolkit';
import globalNotification from './slices/globalNotification';
import assetTypes from './slices/assetTypes/assetTypes';
import workflows from './slices/workflows/workflows';
import questionnaires from './slices/questionnaires/questionnaires';
import availableQuestionnaires from './slices/availableQuestionnaires/availableQuestionnaires';
import questionnaireResponses from './slices/questionnaireResponses/questionnaireResponses';
import questionnaireFields from './slices/questionnaireFields/questionnaireFields';
import recordTypes from './slices/recordTypes/recordTypes';

const rootReducer = combineReducers({
  globalNotification,
  assetTypes,
  workflows,
  questionnaires,
  availableQuestionnaires,
  questionnaireResponses,
  questionnaireFields,
  recordTypes,
});

export default rootReducer;
