import { configureStore, getDefaultMiddleware, Action, AsyncThunkAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import logger from 'redux-logger';

import rootReducer from './rootReducer';
import { Dispatch } from 'react';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false, // allow Redux to store class instances, e.g. of `class HttpErrorResponse`
});

const store = configureStore({
  reducer: rootReducer,
  middleware: process.env.NODE_ENV === 'development' ? customizedMiddleware.concat(logger) : customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch & Dispatch<AsyncThunkAction<unknown, unknown, any>>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export { useAppDispatch as useDispatch, rootReducer };

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
