export const KNOWN_APP_CONFIGURATIONS = {
  AssetInventory: {
    name: 'asset_inventory',
    categories: ['assets'],
  },
  RiskRegister: {
    name: 'risk_register',
    categories: ['risks', 'controls', 'objectives'],
  },
};

export const DEFAULT_APP = KNOWN_APP_CONFIGURATIONS.AssetInventory;
export const UNKNOWN_APP = 'object_inventory';
