import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { enableES5 } from 'immer';
import 'api/serializer';
import SessionProvider from 'providers/SessionProvider';
import App from './components/App/App';
import AppConfig from 'AppConfig';
import queryClient from 'api/queryClient';

import './index.scss';

enableES5();

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_USE_MOCKS === 'true') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

if (process.env.NODE_ENV === 'production') {
  const pendoScript = document.createElement('script');
  pendoScript.type = 'text/javascript';
  pendoScript.crossOrigin = 'use-credentials';
  pendoScript.src = AppConfig.pendoMonitorScript;
  document.head.appendChild(pendoScript);
}

const rumEnabledRegions = ['us', 'ap', 'au', 'eu'];
if (!AppConfig.isGovCloud && !AppConfig.isLocal && rumEnabledRegions.includes(AppConfig.appRegion ?? '')) {
  const rumScript = document.createElement('script');
  rumScript.src = '/rum_monitor.js';
  document.head.appendChild(rumScript);
}

ReactDOM.render(
  <React.StrictMode>
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        {process.env.REACT_APP_DEV_TOOLS === 'true' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
        <App />
      </QueryClientProvider>
    </SessionProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
