import qs from 'qs';
import AppConfig from 'AppConfig';
import { AssetRecordList, AttributeType, AssetRecordType } from 'types';
import http from 'api/http';
import { formatPaginationLinks, handleResponse } from 'api/helpers';
import { deserializeRecords } from '../deserializers';

function deserializeRecordList(recordsJson: any, attributeTypes: AttributeType[]): AssetRecordList {
  const { meta, links } = recordsJson;
  const records = deserializeRecords(recordsJson, attributeTypes);

  return {
    totalCount: meta.count,
    links: formatPaginationLinks(links),
    objects: records,
  };
}

export interface RecordListOptions {
  page: {
    size: number;
    number?: number;
  };
  sort?: string;
  search?: string;
  parent?: string;
}

export const buildGetRecordsUrl = (
  recordTypeId: string,
  { page, sort = 'name', search = '', parent = '' }: RecordListOptions,
): string => {
  const urlParameters: any = { page: { number: page?.number ?? 1, size: page.size } };
  const searchTerm = search?.trim();

  if (sort) {
    urlParameters.sort = sort;
  }

  if (searchTerm || parent) {
    urlParameters.filter = {
      query: searchTerm,
      parent: parent ?? '',
    };
  }

  return `${AppConfig.assetsInventoryApiUrl}/record_types/${recordTypeId}/records?${qs.stringify(urlParameters)}`;
};

export default async function getRecords(
  recordType: AssetRecordType,
  recordListOptions: RecordListOptions,
): Promise<AssetRecordList> {
  const response = await http.get(buildGetRecordsUrl(recordType.id, recordListOptions));

  return await handleResponse(response, (recordsJson) => {
    return deserializeRecordList(recordsJson, recordType.attributeTypes);
  });
}

export async function getRecordsNext(attributeTypes: AttributeType[], url: string): Promise<AssetRecordList> {
  const response = await http.get(url);

  return await handleResponse(response, (recordsJson) => {
    return deserializeRecordList(recordsJson, attributeTypes);
  });
}
