export enum NotificationTypes {
  ASSET_UPDATED_SUCCESS,
  ASSET_DELETED_SUCCESS,
  ASSET_DELETED_ERROR,
  ASSET_UPDATED_CONFLICT_ERROR,
  CUSTOMER_OBJECT_CREATED_SUCCESS,
  RECORD_UPDATED_SUCCESS,
  QUESTIONNAIRE_SENT,
}

export interface DataList<T> {
  totalCount: number;
  objects: T[];
  links: Page<string>;
}
export interface Page<T> {
  first: T;
  previous: T | null;
  next: T | null;
  last: T;
}

export enum SortDirection {
  ASCEND = 'ASCEND',
  DESCEND = 'DESCEND',
}

export interface SortedField {
  fieldName: string;
  direction: SortDirection;
}

export interface PaprikaHTMLEvent {
  target: HTMLInputElement;
}
