import { handleResponse } from 'api/helpers';
import http from 'api/http';
import AppConfig from 'AppConfig';
import { AttributeType } from 'types';
import { Relationship } from 'types/Relationships';
import { deserializeAssetRelationships } from '../deserializers';

export default async function getAssetRelationships(relationship: {
  objectTypeId: string;
  objectId: string;
  adHocAttributeType: AttributeType;
}): Promise<Relationship[]> {
  const { objectTypeId: assetTypeId, objectId: assetId, adHocAttributeType } = relationship;

  const response = await http.get(
    `${AppConfig.assetsInventoryApiUrl}/asset_types/${assetTypeId}/assets/${assetId}/relationships?field_name=${adHocAttributeType.fieldName}`,
  );

  return await handleResponse(response, (json: any) => deserializeAssetRelationships(json, { adHocAttributeType }));
}
